<template>
    <v-dialog
        v-model="modalValue"
        scrollable
        max-width="800px"
        min-width="260px"
        persistent
        content-class="ds-dialog"
    >
        <v-card class="ds-modal-card" elevation="2" dusk="saveShiftTimeModal">
            <modal-toolbar
                :title="shiftTimeId ? 'Shift Time Details' : 'New Shift Time'"
                @close="onClose"/>

            <v-card-text class="ds-modal-content">
                <v-form
                    ref="shiftTimeForm"
                    :lazy-validation="false"
                    class="pt-2"
                >
                    <v-row>
                        <div v-show="isFetchingDetails" style="height:100%">
                            <loading-spinner :value="true"></loading-spinner>
                        </div>

                        <v-col cols="12" class="ds-modal-input-container">
                            <v-text-field
                                name="shift-time-name"
                                label="Shift Name"
                                v-model.trim="shiftTime.name"
                                :error-messages="api.hasError('label')"
                                :rules="rules.required"
                                class="ds-modal-input"
                                autocomplete="off"
                                outlined
                                clearable
                            />
                        </v-col>

                        <v-col cols="12" sm="4" class="ds-modal-input-container">
                            <time-picker
                                v-model="shiftTime.startTime"
                                label="Start Time"
                                :error-messages="api.hasError('start_time')"
                                @onClearTime="onClearStartTime"
                                @onUpdateTime="onConfirmStartTime"/>
                        </v-col>

                        <v-col cols="12" sm="4" class="ds-modal-input-container">
                            <duration-input
                                v-model="shiftTime.duration"
                                :disabled="!start_time"
                                :error-messages="api.hasError('duration')"
                                @onChangeDuration="onChangeDuration"
                            />
                        </v-col>

                        <v-col cols="12" sm="4" class="ds-modal-input-container">
                            <time-picker
                                v-model="shiftTime.endTime"
                                label="End Time"
                                :disabled="!start_time"
                                :error-messages="api.hasError('end_time')"
                                :hint="end_time_hint"
                                @onClearTime="onClearEndTime"
                                @onUpdateTime="onConfirmEndTime"/>
                        </v-col>

                        <v-col cols="12" class="ds-modal-input-container">
                            <v-select
                                name="shift-time-locations"
                                label="Locations"
                                :items="locations"
                                item-value="_id"
                                item-text="name"
                                v-model="shiftTime.locations"
                                :error-messages="api.hasError('locations')"
                                :rules="rules.requiredArrayNotEmpty"
                                :menu-props="{ 'offset-y': true, 'bottom': true, 'content-class': 'fixed-menu-content-class'}"
                                class="ds-modal-input"
                                autocomplete="off"
                                chips
                                deletable-chips
                                outlined
                                multiple
                                clearable
                            >
                                <template v-slot:prepend-item>
                                    <div class="d-flex flex-column justify-center align-center">
                                        <v-btn
                                            color="primary"
                                            text
                                            block
                                            @click="toggleSelectAllLocations"
                                        >
                                            {{ allLocationsSelected ? 'Deselect All' : 'Select All' }}
                                        </v-btn>
                                    </div>
                                </template>
                            </v-select>
                        </v-col>
                    </v-row>

                    <v-row class="mt-0">
                        <v-col
                            :class="{ 'd-flex flex-row justify-space-between': Boolean(shiftTimeId), 'd-flex flex-row justify-end': Boolean(!shiftTimeId) }">
                            <v-btn
                                v-if="Boolean(shiftTimeId)"
                                text
                                color="error"
                                @click="onClickDeleteShiftTime"
                                :disabled="isDeleteDisabled"
                            >
                                Delete Shift Time
                            </v-btn>
                            <v-btn
                                outlined
                                color="primary"
                                :disabled="isSaveDisabled"
                                :loading="isSaving"
                                @click="onSaveButton"
                            >
                                Save
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
        </v-card>
        <delete-modal
            v-model="deleteShiftTimeModalValue"
            title="Delete Shift Time"
            :message="deleteMessage"
            :is-deleting="isDeleting"
            @onDelete="onDeleteShiftTime"
        />
        <unsaved-changes-dialog ref="unsavedChangesDialog"/>
        <confirmation-dialog ref="confirmationDialog"/>
    </v-dialog>
</template>

<script>
import UnsavedChangesDialog from "../../../../../components/modals/UnsavedChangesDialog";
import ModalToolbar from "../../../../../components/modals/components/ModalToolbar";
import LoadingSpinner from "../../../../../components/loaders/LoadingSpinner";
import DeleteModal from "../DeleteModal";
import TimePicker from "../../../../../components/common/TimeRangePicker/TimePicker";
import DurationInput from "../../../../../components/common/TimeRangePicker/DurationInput";
import validationRules from "../../../../../lib/mixins/validationRules";
import timeRangePickerMethods from "../../../../../lib/mixins/timeRangePickerMethods";
import ConfirmationDialog from "../../../../../components/modals/ConfirmationDialog.vue";

export default {
    name: "SaveShiftTimeModal",
    components: {
        ConfirmationDialog,
        UnsavedChangesDialog,
        ModalToolbar,
        LoadingSpinner,
        DeleteModal,
        TimePicker,
        DurationInput
    },
    mixins: [validationRules, timeRangePickerMethods],
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        shiftTimeId: {
            type: String,
            default: null,
        }
    },
    data() {
        return {
            prevShiftTime: {},
            shiftTime: {},
            deleteShiftTimeModalValue: false,
            isFetchingDetails: false,
            isSaving: false,
            isDeleting: false,
            deleteMessage: '',
            timePickerModal: false,
            locations: [],
            timeInputKey: 1,
            customTokens: {
                'h': {pattern: /[0-3]/},
                'H': {pattern: /[0-9]/},
                'm': {pattern: /[0-5]/},
                'M': {pattern: /[0-9]/}
            },
            api: new formHelper(),
        }
    },
    computed: {
        modalValue: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            }
        },
        shiftTimeFormRef() {
            return this.$refs.shiftTimeForm
        },
        isDirty() {
            return !_.isEqual(this.prevShiftTime, this.shiftTime)
        },
        isSaveDisabled() {
            return this.isSaving || this.isDeleting || !this.isDirty
        },
        isDeleteDisabled() {
            return this.isSaving || this.isDeleting
        },
        formattedStartTime() {
            if (typeof this.shiftTime.startTime === 'undefined' || !this.shiftTime.startTime) {
                return ''
            }

            let time = moment(moment().format('YYYY-MM-DD') + ' ' + this.shiftTime.startTime)
            return time.format('h:mm A')
        },
        start_time() {
            return typeof this.shiftTime.startTime !== 'undefined' ? this.shiftTime.startTime : null
        },
        duration() {
            return typeof this.shiftTime.duration !== 'undefined' ? this.shiftTime.duration : null
        },
        start_date_time() {
            if (!this.start_time) {
                return null
            }

            return moment(moment().format('YYYY-MM-DD') + ' ' + this.start_time)
        },
        end_date_time() {
            if (!this.start_date_time || !this.duration) {
                return null
            }

            return this.start_date_time.clone().add(this.duration, 'm')
        },

        end_time() {
            if (!this.end_date_time) {
                return null
            }

            return this.end_date_time.format('h:mm A')
        },
        end_time_hint() {
            if (!this.start_time) {
                return 'Set a start time'
            }

            if (!this.duration) {
                return ''
            }

            if (!this.end_date_time.isSame(this.start_date_time, 'd')) {
                let diff = this.end_date_time.clone().startOf('day').diff(this.start_date_time.clone().startOf('day'), 'd')
                return diff > 0 ? ('+ ' + diff + ' ' + this.$options.filters.pluralize('Day', diff)) : ''
            }

            return ''
        },
        allLocationsSelected() {
            if (this.$authIsAdministrator) {
                return this.shiftTime &&
                    Array.isArray(this.shiftTime.locations) &&
                    Array.isArray(this.locations) &&
                    this.shiftTime.locations.filter(location => !this.disabledLocationsMap.hasOwnProperty(location)).length === this.locations.filter(location => !location.disabled).length
            } else {
                return this.shiftTime && Array.isArray(this.shiftTime.locations) && Array.isArray(this.locations) && this.shiftTime.locations.length === this.locations.length
            }
        },
        authUserLocationsMap() {
            if (this.$user && Array.isArray(this.$user.locations)) {
                return this.$user.locations.reduce((locations, _id) => ({
                    ...locations,
                    [_id]: true
                }), {})
            }
            return {}
        },
        disabledLocationsMap() {
            if (Array.isArray(this.locations)) {
                return this.locations.reduce((locations, location) => {
                    if (location.disabled) {
                        return {
                            ...locations,
                            [location._id]: true
                        }
                    }
                    return locations
                }, {})
            }
            return {}
        }
    },
    methods: {
        onClose() {
            if (this.isDirty) {
                this.showDiscardChangesModal()
                return
            }

            this.closeModal()
        },
        closeModal() {
            this.modalValue = false
            this.resetModal()
        },
        resetModal() {
            this.prevShiftTime = {}
            this.shiftTime = {}
            this.timePickerModal = false
            this.deleteMessage = ''
            this.timeInputKey = this.timeInputKey + 1
            this.locations = []
            if (this.shiftTimeFormRef && this.shiftTimeFormRef.resetValidation) {
                this.shiftTimeFormRef.resetValidation()
            }
            this.api.clearErrors()
        },
        showDiscardChangesModal() {
            this.$refs.unsavedChangesDialog.open()
                .then(() => {
                    this.closeModal()
                })
                .catch(() => {
                })
        },
        onClickDeleteShiftTime() {
            this.deleteShiftTimeModalValue = true
            this.deleteMessage = `
                <p>
                    You are about to delete <b>${this.prevShiftTime.name}</b> from all Locations where it is currently applied.
                </p>
            `
        },
        onDeleteShiftTime() {
            this.isDeleting = true
            this.api.delete(`/shift-time/${this.shiftTimeId}`).then(() => {
                this.$snackNotify('success', 'Shift Time deleted.')
                this.deleteShiftTimeModalValue = false
                this.closeModal()
                this.$emit('onDelete')
            }).catch(console.log).finally(() => this.isDeleting = false)
        },
        fetchDetails(shiftTimeId) {
            this.isFetchingDetails = true
            this.api.get(shiftTimeId ? `/shift-time/details-for-modal/${shiftTimeId}` : '/shift-time/details-for-modal')
                .then(result => {
                    if (result && result.data) {
                        if (Array.isArray(result.data.locations)) {
                            if (this.$authIsAdministrator) {
                                this.locations = result.data.locations.map(location => ({
                                    ...location,
                                    disabled: !this.authUserLocationsMap.hasOwnProperty(location._id)
                                }))
                            } else {
                                this.locations = Array.from(result.data.locations)
                            }
                        }
                        if (this.shiftTimeId && result.data.shift_time) {
                            const shiftTime = {
                                name: result.data.shift_time.label,
                                startTime: result.data.shift_time.start_time,
                                duration: result.data.shift_time.duration,
                                endTime: result.data.shift_time.end_time,
                                locations: _.intersectionBy(
                                    result.data.shift_time.locations,
                                    this.locations,
                                    '_id'
                                ).map(location => location._id),
                            }
                            this.prevShiftTime = {...shiftTime}
                            this.shiftTime = {...shiftTime}
                            this.timeInputKey = this.timeInputKey + 1
                        }
                    }
                }).catch(console.log).finally(() => this.isFetchingDetails = false)
        },
        onSaveButton() {
            if (this.shiftTimeId) {
                const dialog_options = {
                    title: 'Update Shift Time',
                    message: `Updating this Shift Time will update an occurrences of Staffing Automation running. Would you like to continue?`
                }
                this.$refs.confirmationDialog
                    .open(dialog_options)
                    .then(() => {
                        this.onSave()
                    })
                    .catch(() => {
                    })
            } else {
                this.onSave()
            }
        },
        onSave() {
            if (!this.shiftTimeFormRef.validate()) {
                return false
            }

            this.isSaving = true
            this.api.post('/shift-time/save', {
                ...Boolean(this.shiftTimeId) && {_id: this.shiftTimeId},
                label: this.shiftTime.name,
                start_time: this.shiftTime.startTime,
                end_time: this.end_date_time.format('HH:mm'),
                duration: this.shiftTime.duration,
                location_ids: this.shiftTime.locations,
            }).then(() => {
                if (this.shiftTimeId) {
                    this.$snackNotify('success', 'Changes to Shift Time saved.')
                } else {
                    this.$snackNotify('success', 'New Shift Time created.')
                }
                this.closeModal()
                this.$emit('onSave')
            }).catch(console.log).finally(() => this.isSaving = false)
        },
        toggleSelectAllLocations() {
            this.$nextTick(() => {
                if (this.$authIsAdministrator) {
                    const disabledLocations = this.shiftTime && Array.isArray(this.shiftTime.locations) ? this.shiftTime.locations.filter(location => this.disabledLocationsMap.hasOwnProperty(location)) : []
                    this.shiftTime = {
                        ...this.shiftTime,
                        locations: this.allLocationsSelected ? disabledLocations :
                            [
                                ...disabledLocations,
                                ...this.locations.filter(location => !location.disabled).map(location => location._id)
                            ],
                    }
                } else {
                    this.shiftTime = {
                        ...this.shiftTime,
                        locations: this.allLocationsSelected ? [] : this.locations.map(location => location._id),
                    }
                }
            })
        },
        onClearStartTime() {
            this.shiftTime = {
                ...this.shiftTime,
                startTime: null,
                duration: null,
                endTime: null,
            }
        },
        onConfirmStartTime(data) {
            if (this.shiftTime?.endTime) {
                this.setDuration(data, this.shiftTime.endTime)
            } else if (this.duration) {
                this.setEndTime(data, this.duration)
            }
        },
        onChangeDuration(duration) {
            if (!duration) {
                this.shiftTime = {
                    ...this.shiftTime,
                    endTime: null,
                }
            } else {
                this.setEndTime(this.start_time, duration)
            }
        },
        onClearEndTime() {
            this.shiftTime = {
                ...this.shiftTime,
                endTime: null,
            }
        },
        onConfirmEndTime(data) {
            this.setDuration(this.start_time, data)
        },
        setDuration(start_time, end_time) {
            this.shiftTime = {
                ...this.shiftTime,
                duration: this.getUpdatedDuration(start_time, end_time)
            }
        },
        setEndTime(start_time, duration) {
            const end = this.getUpdatedEndTime(start_time, duration)
            if (end) {
                this.shiftTime = {
                    ...this.shiftTime,
                    endTime: end
                }
            }
        },
    },
    watch: {
        value(open) {
            if (open) {
                this.fetchDetails(this.shiftTimeId)
            }
        }
    }
}
</script>

<style scoped>

</style>
